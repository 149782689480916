:focus {
    outline: none;
}

.form-container {
    margin: 100px auto;
    width: 100%;
    position: relative;
    border-radius: 10px;
    height: 600px;
    display: grid;
  }
  .form-container-success {
    margin: auto;
    width: 100%;
    position: relative;
    border-radius: 10px;
    height: 600px;
    display: grid;
  }
  .success {
    width: 100%;
    position: absolute;
    border-radius: 10px;
    height: 100%;
    display: grid;
    background: #52006B;
}
@media(max-width: 1075px){
  .success {
    top: 6px;
  }
}
.shine {
	background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
	background-size: 150px;
  -webkit-background-size: 150px;
	color: #FFC500;
  background-clip: text;
	-webkit-background-clip: text;
  animation-name: shine;
	-webkit-animation-name: shine;
  animation-duration: .3s;
	-webkit-animation-duration: .3s;
  animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}
@-webkit-keyframes shine {
	0%, 10% {
		background-position: -1000px;
	}
	20% {
		background-position: top left;
	}
	90% {
		background-position: top right;
	}
	100% {
		background-position: 1000px;
	}
}
p.success-msg {
  margin-top: -50px;
  height: 100%;
  background: #52006B;
  position: absolute;
  z-index: 999;
  bottom: 0;
  width: 100%;
}
.success.main-form {
  margin-top: -95px;
}
  
  .form-content-left {
    background: linear-gradient(
      90deg,
      rgb(39, 176, 255) 0%,
      rgb(0, 232, 236) 100%
    );
    border-radius: 10px 0 0 10px;
    position: relative;
  }
  
  .form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .form-img-2 {
    width: auto;
    height: 60%;
    position: absolute;
    top: 0%;
    box-shadow: none !important;
    left: 200px;
    animation: float 6s ease-in-out infinite;
}
  
  .form-success {
      /* text-align: center; */
      font-size: 24px;
      /* margin-top: 80px; */
      color: #fff;
      font-weight: 300;
      max-width: 320px;
      /* margin: 0 auto; */
    }
  
  
  .form-content-right {
    position: relative;

  }
  
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form h1 {
    font-size: 1rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .form-inputs {
    margin-bottom: 2rem;
    width: 100%;
    position:relative;
  }
  
  .form-inputs p {
    font-size: 0.8rem;
    /* margin-top: 0.5rem; */
    color: #f00e0e;
    text-align: right;
  }
  
  .form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
  }
  
  .form-input {
    width: 100%;
    border: 0;
    padding: 10px 0px;
    border-bottom: 1px solid #A5A29D;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    transition: all .3s;
    color: #FFC500;
  }
  
  .form-input ~ .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
  }
  
  .form-input:focus ~ .border {
    width: 100%;
    transition: 0.5s;
    border-bottom: 1px solid #A5A29D!important;

  }
  
  .form-input::placeholder {
    color: #595959;
    font-size: 12px;
  }
  
  .form-input-btn {
    width: 80%;
    height: 50px;
    margin-top: 10px;
    border-radius: 2px;
    background: linear-gradient(
      90deg,
      rgb(39, 176, 255) 0%,
      rgb(0, 232, 236) 100%
    );
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
  }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #52006B inset !important;
    }
  
  .form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgb(#FFC500) 0%,
      rgb(#FFC500) 100%
    );
    transition: all 0.4s ease-out;
  }
  
  .form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
  }
  
  .form-input-login a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
  }

  .modal-header {
    font-size: 40px;
    max-width: 390px;
    padding-bottom: 60px;
    text-align: left;
  }
  .modal-header span {
      color: #FFC500;
  }
  @media(max-width: 576px) {
    .modal-container {
      padding: 2rem;
      padding-top: 4rem;
      overflow: hidden;
    }
    .modal-container p {
      font-size: 16px;
    }
    .modal-header {
      font-size: 30px;
    }
    .modal-container .wrapper {
      width: 100%;
    }
  }

.form-button-anim {
    width: 100%;
    margin-top: -33px;
}

[type="file"] {
  /* Style the color of the message that says 'No file chosen' */
    color: #fff;
}
[type="file"]::-webkit-file-upload-button {
  background: #52006B;
  border: 1px solid #FFC500;
  border-radius: 4px;
  color: #FFC500;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 7px;
  text-transform: uppercase;
  transition: all .3s ease;
  margin-right: 10px;
  text-transform: lowercase;
}

[type="file"]::-webkit-file-upload-button:hover {
  background: #FFC500;
  border: 1px solid #52006B;
  color: #52006B;
}
  
@keyframes scaling {
  20% {
    text-shadow: 0px 0 #e8332e, 0px 0 #fc9e05, 0px 0 #891696;
    transform: scale(0.9, 1) rotatey(-10deg);
  }
  40% {
    transform: scale(0.9, 2) translatey(16px);
    text-shadow: -10px 0px #e8332e, -20px 0 #fc9e05, -30px 0 #891696;
  }
  60% {
    transform: scale(0.9, 1) rotatey(-5deg);
    text-shadow: 0px 0 #e8332e, 0px 0 #fc9e05, 0px 0 #891696;
  }
  80% {
    transform: scale(0.9, 2) translatey(16px);
    text-shadow: 10px 0px #e8332e, 20px 0 #fc9e05, 30px 0 #891696;
  }
}
@keyframes jump {
  20% {
    transform: translatey(0) scale(0);
    opacity: 0;
  }
  40% {
    transform: translatey(-50px) scale(1);
    opacity: 1;
  }
  60% {
    transform: translatey(0) scale(0);
    opacity: 0;
  }
  80% {
    transform: translatey(-50px) scale(1);
    opacity: 1;
  }
}