@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}


.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC500;
}
body {
    background: black;
    background-attachment: fixed;
    /* background-image: url(../src/images/bg.png); */
  /* background: #52006B; */
    /* background-attachment: fixed; */
    /* background-image: url(../src/images/bg.png); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* // width: 100vw; */
    height: 100%;
    position: relative;
    overflow-x: hidden;
  
  /* overflow-x: hidden; */
}
@media(max-width: 576px) {
  body {
    background: #000;
  }
}
html {
  /* overflow-x: hidden; */
}
body.fixed-body {
  position: static;
  height: auto;
  overflow-y: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
}
button,
input,
optgroup,
select,
textarea,html input[type="button"],
input[type="reset"],
input[type="submit"],button[disabled],
html input[disabled],button::-moz-focus-inner,
input::-moz-focus-inner, input[type="checkbox"],
input[type="radio"], input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button, input[type="search"], input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

html, body {
  cursor: none;
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"], 
  input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px; }
}

.modal-backdrop {
  background: transparent;
  /* width: 100%; */
  height: 100vh;
  display: grid;
  place-items: end;
  position: absolute;
  top:0;
  right: 0;
}

.modal-container {
  cursor: pointer;
  overflow: hidden;
  width: 882px;
  height: 100%;
  background-color: #52006B;
  border-radius: 0px;
  box-shadow: 5px 5px 10px #1e1e1e2b;
  display: flex;
  flex-direction: column;
  padding-top: 7rem;
  padding-right: 4rem;
  padding-bottom: 7rem;
  padding-left: 4rem;
  align-content: center;
  position: relative;
  justify-content: space-between;
  position: relative;
  color: #E3DCDC;
}
@media(max-width: 1920px){
  .modal-container {
    overflow-y: auto;
  }
}
@media(max-width: 800px){
  .modal-container {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.modal-container .wrapper {
  height: 44.5rem;
  display: flex;
  width: 690px;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
@media(max-width: 768px){
  .modal-container .wrapper {
    width: 100%;
  }
}
@media(max-width: 576px) {
  .modal-container {
    padding: 2rem;
    padding-top: 4rem;
    overflow-y: auto;
    position: fixed;

  }
  .modal-container .wrapper {
    height: 42rem;
  }
  .modal-container p {
    font-size: 16px;
  }
  .modal-header {
    font-size: 30px;
  }
  .modal-container .wrapper {
    width: 100%;
  }
}

.modal-container p {
  font-size: 21px;
  line-height: 30px;
  max-width: 600px;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform .3s 0s;
  transition: -webkit-transform .3s 0s;
  transition: transform .3s 0s;
  transition: transform .3s 0s, 
              -webkit-transform .3s 0s;
              -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
  -webkit-transition: background 0.3s, 
                      -webkit-transform 0.3s 0.6s;
  transition: background 0.3s, 
              -webkit-transform 0.3s 0.6s;
  transition: transform 0.3s 0.6s, 
              background 0.3s;
  transition: transform 0.3s 0.6s, 
              background 0.3s, 
              -webkit-transform 0.3s 0.6s;
  transition-duration: .5s;
}
@media(max-width: 576px){
  .close-modal {
    top: 40px;
    right: 25px;
  }
}

.button {
  /* background-image: url(../public/mail.png); */
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;

}

@media screen and (max-width: 840px) {
  .modal-container {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .background {
    width: auto;
  }
  .modal-backdrop {
    left: 0;
    position: fixed;
    height: 100%;
  }
}

 
.main p {
    position: absolute;
    transform: rotate(-90deg);
    top: 400px;
    font-size: 20vw;
    font-weight: 700;
    font-family: "Avenir";
    letter-spacing: 10px;
  }
  .main .container {
    width: 1440px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-items: center;
    visibility: hidden;
  }


.img-container {
  width: 1440px;
  height: 960px;
  position: relative;
  overflow: hidden;}
  .img-container:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    right: 0;
  }
  .img-container img {
    width: 1440px;
    margin: 0 auto;
  
}

.maintext {
  /* transform: translateY(200px); */
    max-width: 555px;
    line-height: 35px;
    /* margin-left: 250px; */
    padding-top: 150px;
    /* padding-left: 155px; */
    position: absolute;
    right:0;
    transform:translateX(15rem);
}
@media(max-width: 576px){
  .maintext {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    transform: translateX(0);
  }
}
.maintext h1 {
  font-weight: 400;
}
.maintext h1 span {
  color: white;
}

.box {
  width: 150px;
  height: 150px;
}
@media(max-width: 576px) {
  .box:not(.box-star) img {
    width: 150px;
    height: 150px;
    position: absolute;
    z-index: -1;
    top: 35%;
    object-fit: contain;
  }
}
.box.box-right {
  position: absolute;
  right: 30%;
  clear: both;
}
.box.right {
  position: absolute;
  right: 400px;
}
.magic {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}

.title .main {
  max-width: 450px;
  font-size: 40px;
  color: white;
  /* padding-top: 200px; */
}
@media(max-width: 380px) {
  .title .main {
    font-size: 35px;
  }
}

/* .title .main.jobs-main {
  padding-top: 0;
} */

/* @media(max-width: 576px){
  .about-section .title .main {
    padding-top: 40px;
    margin-top: -100px;
  }
} */

.about-section {
  padding-top: 100px;
}
.title.jobs-section {
  padding-top: 3rem;
}
.title .main .glow {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  color: #fdec6e;
}
.glow-no-hover {
  color: #fdec6e;
}
.glow {
  color: #fdec6e;
  text-shadow:0px 0px 40px #fdec6e;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.list-wrap {
  padding-top: 70px;
  /* position: absolute; */
  /* z-index: 991; */
}
@media(max-width: 576px){
  .list-wrap .items {
    padding-right: 50px;
  }
}
.list-wrap.navHidden {
  z-index: 991;
}
.list-wrap.navVisible {
  z-index: 3;
}
.items-wrap {
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: flex-start;
  cursor: pointer;

}
.list-wrap .one {
  padding-bottom: 50px;
  padding-bottom: 50px;
  z-index: 1;
  position: relative;
  width: fit-content;
}

.items-wrap p {
  color: white;
  margin-right: 25px;
  font-size: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #FFC500,
    #FFC500 50%,
    white 50%);
    background-size: 200% 100%;
    background-position: 100%;
}
@media(max-width: 576px) {
  .items-wrap p {
    width: 80%;
  }
}
.items-wrap:hover p {
  transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
  background-position: 0%;
}
.items-wrap .arrow path {
  transition: .1s ease-out; 
  transition-delay: .35s;
}
.items-wrap:hover .arrow path {
  fill:#FFC500;
}

.container {
  color: beige;
}

.navich {  
  /* display: flex;
  justify-content: space-between;
  padding: 35px 45px; */
}
 .navich .logo-container {
    overflow-y: hidden;
}
    
.logo-container h1 {
  text-transform: uppercase;
  font-size: 15px;
}
.menu-container {
    overflow-y: hidden;
}

.menu-container div {
  text-transform: uppercase;
  font-size: 13px;
}
  
.menu-container div:hover {
    cursor: pointer;
}

.mobile-menu {
  position: fixed;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 45px;
  top: 0;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #52006B;
  z-index: 99;
}
@media(max-width: 993px){
  .mobile-menu {
    width: 85%;
  }
}
@media(max-width: 576px){
  .mobile-menu {
    width: 100%;
    padding: 30px;
  }
}

.mobile-menu .close-button {
  align-self: flex-end;
  margin: 35px 45px 0 0;
  outline: none;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 13px;
  color: white;
}
@media(max-width: 576px){
  .mobile-menu .close-button {
    position: absolute;
    z-index: 9999;
    margin: 20px;
  }
}
.mobile-menu button:hover {
  cursor: pointer;
}

.mobile-menu ul {
  list-style: none;
  margin-top: 40px; 
} 
    
.mobile-menu ul li {
  margin: 20px 0;
  overflow-y: hidden;   
  user-select: none;  
} 
      
.mobile-menu ul li div {
  text-align: left;
  text-transform: none;
}
  

.contact {
  margin-top: 80px;
  display: flex;
}
.contact h5 {
  font-weight: normal;
}

.planets {
  position: absolute;
  width: 100%;
  left: 78px;
  top: -100px;
  z-index: 0;
}
@media(max-width: 576px){
  .planets {
    left: 165px;
    top: -10rem;
    /* animation: float 6s ease-in-out infinite; */
  }
}
@media(max-width: 380px) {
  .planets {
    left: 40px;
    top: 0rem;
  }
}
.confetti {
  position: absolute;
  height: 35rem;
}
@media(max-width: 576px) {
  .confetti {
    display: none;
  }
}





@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-30px);
	}
	100% {
		transform: translatey(0px);
	}
}
.imageWoman {
  animation: none !important;
}
#parallax {
  animation: none !important;
}
.imageWoman img {
  position: absolute;
  width: auto;
  height: 400px;
  top: 5rem;
  right: 0;
  animation: float 6s ease-in-out infinite;

}


@media (max-width: 576px){
  .imageWoman img {
    display: none;
  }
}

.contact-form{
  max-width:calc(100vw - 40px);
  box-sizing:border-box;
  position:relative;
}

.contact-form input{
  width: 100%;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 0;
  height: 70px;
  padding-bottom: 14px;
  box-sizing: border-box;
  background: none;
  padding-bottom: 20px;
  outline: none;
  resize: none;
  border: 0;
  font-family: 'Montserrat',sans-serif;
  transition: all .3s;
  border-bottom: 1px solid #bebed2;
  color: #FFC500;

}
input:focus{
  border-bottom: 1px solid #A5A29D;
}

.btn-form:before{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  -100%;
  left: -100%;
  background: #FFC500;
  transition: all .3s;
  z-index: -1;
  
}
.btn-form:hover{
  background:#ffc4004d;
  color:#fff
}
.btn-form:hover{
  color: white;
}
.btn-form:hover:before{			
  top: 0;
  left: 0;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  margin-bottom: 10px;
}

#message {
  height: 180px;
  padding: 0px 0px;
}


.btn-form span{
  position: absolute;
  display: block;
}
.btn-form span:nth-child(1){
  height: 1px;
  width:200px;
  top:0px;
  left:-200px;
  background: linear-gradient(to right, rgba(0,0,0,0), #FFC500);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span1 2s linear infinite;
  animation-delay: 1s;
}

@keyframes span1{
  0%{
      left:-200px
  }
  100%{
      left:200px;
  }
}
.btn-form span:nth-child(2){
  height: 70px;
  width: 2px;
  top:-70px;
  right:0px;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #FFC500);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span2 2s linear infinite;
  animation-delay: 2s;
}
@keyframes span2{
  0%{
      top:-70px;
  }
  100%{
      top:70px;
  }
}
.btn-form span:nth-child(3){
  height:1px;
  width:200px;
  right:-200px;
  bottom: 0px;
  background: linear-gradient(to left, rgba(0,0,0,0), #FFC500);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  animation: span3 2s linear infinite;
  animation-delay: 3s;
}
@keyframes span3{
  0%{
      right:-200px;
  }
  100%{
      right: 200px;
  }
}

.btn-form span:nth-child(4){
  height:70px;
  width:2px;
  bottom:-70px;
  left:0px;
  background: linear-gradient(to top, rgba(0,0,0,0), #FFC500);
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  animation: span4 2s linear infinite;
  animation-delay: 4s;
}
@keyframes span4{
  0%{
      bottom: -70px;
  }
  100%{
      bottom:70px;
  }
}

.btn-form:hover{
  transition: all .2s;
  /* transform: rotate(-4deg) scale(1.1); */
  box-shadow: 0px 3px 5px rgba(0,0,0,.4);
}
.btn-form:hover span{
  animation-play-state: paused;
}

.btn-form{
    width:200px;
    height:70px;
    background: transparent;
    border-style: none;
    color:#fff;
    font-size: 23px;
    letter-spacing: 3px;
    font-family: 'Lato';
    font-weight: 600;
    outline: none;
    cursor: pointer;
    position: relative;
    padding: 0px;
    overflow: hidden;
    transition: all .5s;
    border:1px solid #A5A29D;
    float:right;
    /* margin-top: -17px; */
}
.disabled-btn {
  cursor: not-allowed;
}
/* .disabled-btn:hover {
  background: none;
  box-shadow: none;
  transform: none;
} */
@media(max-width: 576px){
  .btn-form {
    width: 150px;
  }
}

.btn-form:hover .form-icon path {
  fill: #52006B;
}

.nav-mail-ico path {
  transition: all .2s linear;
}
.nav-mail-ico:hover path {
  fill: #FFC500;
}
.mail-white path {
  fill:white;
}

svg.mail-white {
  transition: all .3s ease-in-out;
}
svg.mail-white:hover {
  transform: scale(1.5);
}

.form-icon path {
  fill: white;
}


.cursor {  
  position: fixed;
  width: 30px;
  height: 30px;
  top: -50%;
  left: -50%;
  margin: -15px 0 0 -15px;
  border-radius: 50%;
  background-color: white;
  backface-visibility: hidden;
  transition: transform 0.2s ease-out;
  mix-blend-mode: difference;
  z-index: 1000;
  pointer-events: none;
}
@media(max-width: 768px){
  .cursor {
    display: none;
  }
}

@keyframes cursorAnim {
  0% {
  transform: scale(1);
  }
  50% {
  transform: scale(5);
  }
  100% {
  transform: scale(1);
  opacity: 0;
  }
}
  .expand {
    animation: cursorAnim .5s forwards;
  }

  p.fade {
    font-size: 20vw;
    padding-bottom: 30px;
    color: white;
  }

  .job-title {
    font-size: 40px;
    margin-top: -80px;
    margin-bottom: 60px;
    width: 800px;
  }
  @media(max-width: 576px){
    .job-title {
      margin-top: auto;
      margin-bottom: auto;
      width: auto;
    }
  }
  .job-title span {
    color: #FFC500;
  }

  .job-subtitle {
    font-size: 15px;
    color: #FFC500;
  }

  .job-content {
    display: flex;
    justify-content: space-between;
  }
  .job-content.first {
    padding-top: 30px;
    padding-bottom: 10px;
  }
  @media(max-width: 576px) {
    .job-content {
      flex-direction: column;
    }
  }
  .job-content div {
    width: 45%;
  }
  @media(max-width: 576px) {
    .job-content div {
      width: 100%;
    }
  }
  .job-content ul {
    /* position: relative; */

  }
  .job-content ul li {
    position: relative;
    padding-left: 20px;
  }
  .job-content li:before {
    content: '';
    background: #C8A962;
    width: 6px;
    height: 6px;
    border-radius: 0;
    position: absolute;
    left: 0px;
    top: 6px;
  }


@media(max-width: 576px){
  .App {
    position: relative;
  }
}
@media(max-width: 576px){
  .mobile-hide {
    display: none;
  }
}

.form-jobs {
  padding-top: 30px;
}
@media(max-width: 1075px){
  .formWrap {
    position: relative;
    padding-top: 30px;
  }
}
@media(max-width: 430px){
  .formWrap {
    padding-top: 55px;
  }
}
/* @media(max-width: 1075px) {
  .form-jobs {
    padding-top: 65px;

  }
} */
.text-wrap {
      /* right: 0; */
    /* position: absolute; */
    /* top: -500px; */
    right: 0;
    /* position: absolute; */
    top: 0px;
    max-width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    z-index: 1;
    position: relative;
    /* flex-basis: auto;
}
@media(max-width: 576px) {
  .text-wrap {
    left: 0;
    width: 100%;
  }
}
.text-wrap .title {
  /* padding-top: 130px; */
}
/* @media(min-width: 768px) {
  .text-wrap {
    top: 80px;
  }
} */
.columns {
  padding-top: 70px;
  display: flex;
  color: white;
  max-width: 1000px;
  justify-content: space-between;
}
@media(min-width: 1900px) {
  .columns {
    font-size: 22px;
  }
}
@media(max-width: 576px) {
  .columns {
    flex-direction: column;
  }
}
.columns div {
  width: 46%;
}
@media(max-width: 576px) {
  .columns div {
    width: 100%;
  }
}

span.falling{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1)  ;
  animation:  animate 3s linear infinite;
}
span.falling::before{
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg, #fff, transparent);
}
@keyframes animate {
  0%
  {
      transform: rotate(315deg) translateX(0);
      opacity: 1;
  }
  70%
  {
      opacity: 1;

  }
  100%
  {
      transform: rotate(315deg) translateX(-1500px);
      opacity: 0;

  }
  
}
span.falling:nth-child(1){
  top: -10px;
  right: 0;
  left:initial;
  animation-delay:5s;
  animation-duration: 15s;
}
.ti-cursor {
  display: none !important;
}

span.falling:nth-child(2){
  top: -10px;
  right: 200px;
  left:initial;
  animation-duration: 5s;
}
.toptop {
  -webkit-animation: slide-out-top 2.5s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: slide-out-top 2.5s cubic-bezier(.215, .61, .355, 1) forwards;
  right: 0;
  color: black;
}
/* @-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
} */
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(-320rem);
    transform: translateY(-320rem);
    
  
  }
  50% {
    color: rgba(0, 0, 0, 0.459);
  }
  to {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    color: #fff;
  }
}

.dash {
  margin: 0 15px;
  width: 35px;
  height: 15px;
  border-radius: 8px;
  background: #52006B;
}

.uno {
  margin-right: -8px !important;
  transform-origin: center left;
  animation: spin 3s linear infinite;  
}

.dos {
  transform-origin: center right;
  animation: spin2 3s linear infinite;
  animation-delay: .2s;
}

.tres {
  transform-origin: center right;
  animation: spin3 3s linear infinite;
  animation-delay: .3s;
}

.cuatro {
  transform-origin: center right;
  animation: spin4 3s linear infinite;
  animation-delay: .4s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(360deg);
  }
  30% {
    transform: rotate(370deg);
  }
  35% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-180deg);
  }
  35% {
    transform: rotate(-190deg);
  }
  40% {
    transform: rotate(-180deg);
  }
  78% {
    transform: rotate(-180deg);
  }
  95% {
    transform: rotate(-360deg);
  }
  98% {
    transform: rotate(-370deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin3 {
  0% {
    transform: rotate(0deg);
  }
  27% {
    transform: rotate(0deg);  
  }
  40% {
    transform: rotate(180deg);
  }
  45% {
    transform: rotate(190deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  80% {
    transform: rotate(370deg);
  }
  85% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin4 {
  0% {
    transform: rotate(0deg);
  }
  38% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-360deg);
  }
  65% {
    transform: rotate(-370deg);
  }
  75% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}



@keyframes fade-in-scale-down{
  0%{
    opacity:0;
    -webkit-transform:scale(1.1);
    -ms-transform:scale(1.1);
    transform:scale(1.1)
  }
  
  100%{
    opacity:1;
    -webkit-transform:scale(1);
    -ms-transform:scale(1);
    transform:scale(1);
  }
}

@-moz-keyframes glow-star {
  40% { -moz-opacity: 0.3;}
  90%,100% { -moz-opacity: 1; -moz-transform: scale(1.2);}
}
@-webkit-keyframes glow-star {
  40% { -webkit-opacity: 0.3;}
  90%,100% { -webkit-opacity: 1; -webkit-transform: scale(1.2);}
}
@keyframes glow-star{
  40% { -webkit-opacity: 0.3; opacity: 0.3;  }
  90%,100% { -webkit-opacity: 1; opacity: 1; -webkit-transform: scale(1.2); transform: scale(1.2); border-radius: 999999px;}
}
.glowing_stars .star{
  position: absolute;
  border-radius: 100%;
  background-color: #fff;
  width: 3px;
  height: 3px;
  opacity: 0.5;
  will-change: opacity;
}

.glowing_stars .star:nth-child(1){
  top: 60%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 1s;
}
.glowing_stars .star:nth-child(2){
  top: 20%;
  left: 40%;
  width: 4px;
  height: 4px;
  animation: glow-star 2s infinite ease-in-out alternate 3s;
}
.glowing_stars .star:nth-child(3){
  top: 25%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 5s;
  background-color: #fdec6e;
}
.glowing_stars .star:nth-child(4){
  top: 75%;
  left: 80%;
  animation: glow-star 2s infinite ease-in-out alternate 7s;
}
.glowing_stars .star:nth-child(5){
  top: 55%;
  left: 50%;
  animation: glow-star 2s infinite ease-in-out alternate 9s;
}
.glowing_stars .star:nth-child(6){
  top: 20%;
  left: 40%;
  width: 3px;
  height: 3px;
  animation: glow-star 1.5s infinite ease-in-out alternate 5s;
  background-color: #fdec6e;
}
.glowing_stars .star:nth-child(7){
  top: 5%;
  left: 45%;
  width: 3px;
  height: 3px;
  animation: glow-star 2.5s infinite ease-in-out alternate 3s;
}
.glowing_stars .star:nth-child(8){
  top: 35%;
  left: 45%;
  width: 3px;
  height: 3px;
  animation: glow-star 2s infinite ease-in-out alternate 1s;
}
.glowing_stars .star:nth-child(9){
  top: 40%;
  left: 50%;
  width: 2px;
  height: 2px;
  animation: glow-star 2s infinite ease-in-out alternate 5s;
}.glowing_stars .star:nth-child(10){
  top: 50%;
  left: 40%;
  width: 5px;
  height: 5px;
  animation: glow-star 1.5s infinite ease-in-out alternate 5s;
}
.glowing_stars .star:nth-child(11){
  top: 64%;
  left: 45%;
  animation: glow-star 3s infinite ease-in-out alternate 4s;
}
.glowing_stars .star:nth-child(12){
  top: 70%;
  left: 85%;
  width: 4px;
  height: 4px;
  animation: glow-star 2s infinite ease-in-out alternate 1s;
}
.glowing_stars .star:nth-child(13){
  top: 68%;
  left: 15%;
  width: 4px;
  height: 4px;
  animation: glow-star 1s infinite ease-in-out alternate 1s;
}
progress {
	-webkit-appearance: none;
	appearance: none;
	position: fixed;
	top: 0;
	left: 0;
	transform: translate(calc(-50% + 3px), calc(50vh - 50%)) rotate(90deg);
	width: 100vh;
	height: 6px;
}

progress::-webkit-progress-bar {
	background-color: #f5f5f5;
}

progress[value="100"]::-webkit-progress-bar {
	background-color: #fd79a8;
}

progress::-webkit-progress-value {
	background-color: #fd79a8;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
}

.reading-progress-bar {
  position: sticky;
  height: 5px;
  top: 0;
  background-color: #ff0000;
}

.social-media-wrap{
  width: 120px;
  justify-content: space-between;
  display: flex;
}
.social-media-wrap svg {
  transition: all .3s ease-in-out;
}
.social-media-wrap svg:hover {
  transform: scale(1.5);
}
.sidebar-footer {
  padding-top: 30px;
  /* height: 100%; */
}
.sidebar-footer .performcbWhite {
  transform: scale3d(.8, .8, .8);
  position: absolute;
  left: 18px;
  bottom: 90px;
  z-index: -9;
}

.desktopFooter {
  z-index: 9;
}
.mobileFooter {
  display: none;
}
@media screen and (min-width: 1020px) and (max-width: 1200px){
  .desktopFooter {
    top: 200px !important;
  }
}
@media screen and (min-width: 575px) and (max-width: 870px){
  .desktopFooter {
    top: 300px !important;
  }
}
@media(max-width: 576px) {
  .desktopFooter {
    display: none;
  }
  .mobileFooter {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .footer-patch {
    display: none;
  }
}
@media (max-width: 380px){
  .desktopFooter {
    top: auto !important;
  }
}
@media (max-width: 360px){
  .desktopFooter {
    top: 6rem !important;
  }
}
/* @media (max-width: 350px){
  .desktopFooter {
    top: 400px !important;
  }
} */

.footer-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 50px;
  position: absolute;
  z-index: 9;
  max-width: 124rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 10rem;
  flex-wrap: wrap;
}
@media(max-width: 768px) {
  .footer-wrapper {
    padding: 0 30px;
    padding-bottom: 5rem;
  }
}
@media(max-width: 576px) {
  .footer-wrapper {
    padding: 0 30px;
    padding-bottom: 3rem;
  }
}
.footer-wrapper .info {
  text-align: left;
}
@media(max-width: 576px) {
  .footer-wrapper .info {
    padding-bottom: 1rem;
  }
}

.footer-wrapper p a {
  color: #FFC500;
  text-decoration: none;
  font-weight: 500;
}
.hovertext-container{
  width: 355px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.hovertext-container p{
  position: absolute;
  display: block;
  top: 3px;
  transition: all 0.5s ease;  
  font: 16px sans-serif;
   /* color: # */
}
.hovertext-container .hovertext-p2{
  transform: translateY(38px);
  width: 355px;
  color: #FFC500;
  cursor: pointer;
}

.hovertext-container:hover .hovertext-p1{
 transform: translateY(-100px);
}

.hovertext-container:hover .hovertext-p2{
  transform: translateY(0px);
}


@media(max-width: 576px){
  .partner-logo-mobile {
    transform: scale(1);
  }
}

p.error .yay {
  display: none;
}

.jobsPage {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 0 1rem; */
  height: 100%;
  cursor: pointer;
  color: white;
}
@media(max-width: 576px){
  .jobsPage {
    padding:0;
  }
}

.zIndex {
  z-index: -1;
}
.minus {
  z-index:999;
}

@media(max-width: 576px){ 
  .mobile-hide {
    display: none;
  }
}

/* #parallax {
  top: -48vh;
}
@media(max-width: 576px){
  #parallax {
    top: -40vh;
  }
} */

nav#home {
  /* position: sticky; */
  /* top: 0px;
  height: 6rem;
  margin: 0px; */
  /* background-color: #FFC500; */
}
/* @media(max-width: 576px) {
  nav#home {
    position: fixed;
    z-index: 10;
  }
} */

.footer-patch {
  left: -200px;
  position: absolute;
  bottom: 300px;
  height: 172px;

}
@media(max-width: 1025px) {
  .footer-patch {
    top: 520px;
  }
}
/*  */
.form-errors {
  color: red;
  text-align: left;
  position: absolute;
  /* max-width: 100% !important; */
}
.form-errors.cv-field-error {
  right: 0;
  top: 51px;
  position: absolute;
}
@media(max-width: 576px){
  .form-errors {
    font-size: 10px !important;
  }
}

.form-success-message {
  position: absolute;
  top: -5rem;
  font-size: 18px;
  color: #FFC500;
}
@media(max-width: 520px) {
  .form-success-message {
    top: -4rem;
    font-size: 16px;
  }
}

@media(min-width: 1900px){
  .footer-wrapper {
    font-size: 22px;
  }
  .hovertext-container {
    height: 50px;
  }
  .hovertext-container p {
    top: 8px;
    font: 26px sans-serif;
  }
}

.footer-wrapper p {
  color: white;
}
@media(max-width: 576px){
  .footer-wrapper p {
    padding-bottom: 5px;
  }
}

.close-modal:hover {
  transform: rotate(20deg);
  transform: scale(1.2);
}
.close-modal::after, .close-modal::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #FFC500;
}
.close-modal::before {
  -webkit-transform: translateX(-50%)
                      translateY(-50%)
                      rotate(45deg);
  -ms-transform: translateX(-50%)
                  translateY(-50%)
                  rotate(45deg);
  transform: translateX(-50%)
              translateY(-50%)
              rotate(45deg);
  transition-duration: .5s;
}
.close-modal::after {
  -webkit-transform: translateX(-50%)
                      translateY(-50%)
                      rotate(-45deg);
  -ms-transform: translateX(-50%)
                  translateY(-50%)
                  rotate(-45deg);
  transform: translateX(-50%)
              translateY(-50%)
              rotate(-45deg);
  transition-duration: .5s;
}

.close-modal:hover::before, .close-modal:hover::after{
  -webkit-transform: translateX(-50%)
                      translateY(-50%)
                      rotate(-180deg);
  -ms-transform: translateX(-50%)
                  translateY(-50%)
                  rotate(-180deg);
  transform: translateX(-50%)
              translateY(-50%)
              rotate(-180deg);
}


/* #jobs::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(24, 10, 32, 0.4) 62.78%,
    rgba(88, 38, 117, 0.4) 92.78%,
    rgba(53, 18, 73, 0.58) 92.78%,
    #2d0350 100%
  );
} */

.mobile-menu::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #fff3;
  -webkit-border-radius: 1ex;
}

.mobile-menu::-webkit-scrollbar-thumb {
  background: #ffc500;
  -webkit-border-radius: 1ex;
}

.mobile-menu::-webkit-scrollbar-corner {
  background: #fff3;
}